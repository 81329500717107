import api from '@/api/api';
import { Model } from '@/interfaces/Model';
import { ModelForm } from '@/interfaces/ModelForm';
import { confirmDialog } from 'primereact/confirmdialog';
import { toast } from 'react-toastify';

// List all models
export const getModels = async () => {
  try {
    const response = await api.get('/model?size=100000');
    return response.data;
  } catch (error) {
    toast.error('Failed to fetch models.');
    throw error;
  }
};

// Create model
export const createModel = async (data: ModelForm) => {
  try {
    await api.post('/model', data);
    toast.success('Model created successfully.');
  } catch (error: any) {
    if (error?.response?.data?.detail) {
      // Extract missing fields and create an error message
      const missingFields = error.response.data.detail
        .filter((err: any) => err.type === 'missing')
        .map((err: any) => err.loc[1]) // Assuming loc[1] holds the field name
        .join(', ');

      const errorMessage = `Missing fields: ${missingFields}`;
      toast.error(errorMessage);
    } else {
      const errorMessage = error?.response?.data?.message || 'Failed to create model.';
      toast.error(errorMessage);
    }
    throw error;
  }
};

// Deploy model
export const deployModel = async (data: Model, onActionComplete: () => void) => {
  const accept = async () => {
    try {
      await api.post(`/model/${data.uid}/deploy`);
      toast.success('Model deployed successfully.');
      onActionComplete();
    } catch (error) {
      // @ts-ignore
      if (error?.response?.data?.detail) {
        // @ts-ignore
        toast.error(`Error: ${error.response.data.detail}`);
      } else {
        toast.error('Failed to deploy model.');
      }
      throw error;
    }
  };

  const reject = () => {
    // toast.info('Deploy was cancelled.');
  };

  confirmDialog({
    header: 'Deploy model',
    message: `Do you really want to deploy "${data.title}" model?`,
    rejectClassName: 'btn-secondary',
    acceptClassName: 'btn-primary',
    accept,
    reject,
  });
};

// Run model
export const runModel = async (data: Model) => {
  try {
    await api.post(`/model/${data.uid}/run`);
    toast.success('Model run successfully.');
  } catch (error) {
    // @ts-ignore
    if (error?.response?.data?.detail) {
      // @ts-ignore
      toast.error(`Error: ${error.response.data.detail}`);
    } else {
      toast.error('Failed to run model.');
    }
    throw error;
  }
};

// Delete model
export const deleteModel = async (data: Model, onActionComplete: () => void) => {
  const accept = async () => {
    try {
      await api.delete(`/model/${data.uid}`);
      toast.success('Model deleted successfully.');
      onActionComplete();
    } catch (error) {
      // @ts-ignore
      if (error?.response?.data?.detail) {
        // @ts-ignore
        toast.error(`Error: ${error.response.data.detail}`);
      } else {
        toast.error('Failed to delete model.');
      }
      throw error;
    }
  };

  const reject = () => {
    // toast.warn('Deletion cancelled.');
  };

  confirmDialog({
    header: 'Delete Confirmation',
    message: `Do you want to delete model "${data.title}"?`,
    rejectClassName: 'btn-secondary',
    acceptClassName: 'btn-primary',
    accept,
    reject,
  });
};
