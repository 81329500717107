import api from '@/api/api';
import { toast } from 'react-toastify';

export const getFilters = async () => {
  try {
    const response = await api.get('/filters');
    return response.data;
  } catch (error) {
    toast.error('Failed to fetch filters.');
    throw error;
  }
};
