import { getUser } from '@/api/api';
import { InvalidateQueryFilters, UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

type ApiGetOptions<TData, TError> = Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>;
type ApiSendOptions<TData, TError, TVariables> = Omit<
  UseMutationOptions<TData, TError, TVariables>,
  'mutationFn' | 'onSuccess' | 'onError'
>;

export const useApiGet = <TData, TError>(key: unknown[], fn: () => Promise<TData>, options?: ApiGetOptions<TData, TError>) => {
  const user = getUser();
  return useQuery<TData, TError>({
    queryKey: key,
    queryFn: fn,
    enabled: !!user,
    ...options,
  });
};

export const useApiSend = <TData, TError, TVariables>(
  fn: (variables: TVariables) => Promise<TData>,
  success?: (data: TData) => void,
  error?: (err: TError) => void,
  invalidateKey?: Array<InvalidateQueryFilters>,
  options?: ApiSendOptions<TData, TError, TVariables>
) => {
  const queryClient = useQueryClient();

  return useMutation<TData, TError, TVariables>({
    mutationFn: fn,
    onSuccess: (data) => {
      if (invalidateKey) {
        invalidateKey.forEach((key) => {
          queryClient.invalidateQueries(key);
        });
      }
      if (success) success(data);
    },
    onError: error,
    retry: 0,
    ...options,
  });
};
