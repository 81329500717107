import { getUser } from '@/api/api.ts';
import { deleteModel, deployModel, runModel } from '@/api/services';
import { PipeCanceled, PipeFailed, PipePaused, PipeRunning, PipeSuccess } from '@/components/icons';
import { Model } from '@/interfaces/Model';
import { CirclePlay, CloudUpload, CopyIcon, FileCode2, Trash } from 'lucide-react';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useRef } from 'react';

const getLabel = (color: string) => {
  switch (color) {
    case 'black':
      return 'bg-black text-white border-black';
    case 'green':
      return 'bg-green-50 text-green-500 border-green-100';
    case 'blue':
      return 'bg-blue-50 text-blue-500 border-blue-100';
    case 'orange':
      return 'bg-orange-50 text-orange-500 border-orange-100';
    case 'yellow':
      return 'bg-yellow-50 text-yellow-600 border-yellow-100';
    default:
      return 'bg-gray-50 text-gray-500 border-gray-100';
  }
};

const getRateColor = (rate: number) => {
  let classes;

  if (rate < 80) {
    classes = 'bg-red-100 border-red-200';
  } else if (rate >= 80 && rate < 90) {
    classes = 'bg-orange-100 border-orange-200';
  } else if (rate >= 90) {
    classes = 'bg-green-100 border-green-200';
  } else {
    classes = '';
  }

  return classes;
};

const getPipelineIcon = (status: string) => {
  switch (status) {
    case 'new':
      return <PipePaused />;
    case 'running':
      return <PipeRunning />;
    case 'success':
      return <PipeSuccess />;
    case 'failed':
      return <PipeFailed />;
    case 'canceled':
      return <PipeCanceled />;
    default:
      return;
  }
};

const getCzechDate = (itemDate: Date) => {
  const dateObj = new Date(itemDate);
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  const date = dateObj.toLocaleDateString('cs-CZ', dateOptions);
  const time = dateObj.toLocaleTimeString('cs-CZ', timeOptions);

  return (
    <div className={'flex items-center gap-x-1'}>
      <span>{date}</span>
      <span className={'text-xs text-gray-400'}>{time}</span>
    </div>
  );
};

const getActionLink = (
  data: Model,
  action: string,
  onActionComplete: () => void,
  setDuplicateModelVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  switch (action) {
    case 'run':
      return (
        <CirclePlay
          size={20}
          className={`cursor-pointer transition-all hover:text-blue-500`}
          onClick={async () => {
            await runModel(data);
            onActionComplete();
          }}
        />
      );
    case 'duplicate':
      return (
        <CopyIcon
          size={20}
          className={`cursor-pointer transition-all hover:text-yellow-700`}
          onClick={async () => {
            setDuplicateModelVisible(true);
          }}
        />
      );
    case 'delete':
      return (
        <Trash
          size={20}
          className={'cursor-pointer transition-all hover:text-red-500'}
          onClick={async () => {
            await deleteModel(data, onActionComplete);
            onActionComplete();
          }}
        />
      );
    case 'deploy':
      return (
        <CloudUpload
          size={20}
          className={'cursor-pointer transition-all hover:text-orange-500'}
          onClick={async () => {
            await deployModel(data, onActionComplete);
            onActionComplete();
          }}
        />
      );
    default:
      return null;
  }
};

export const titleWithImageBodyTemplate = (rowData: Model) => {
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const overlayPanelImg = useRef<OverlayPanel>(null);
  const user = getUser();

  return (
    <div className={'flex items-center gap-x-2'}>
      {rowData.template?.picture_id && user?.access_token && (
        <img
          src={`${import.meta.env.VITE_API_URL}/picture/${rowData.template?.picture_id}?tid=${user?.access_token}`}
          alt={rowData.template?.title}
          className={'min-h-8 min-w-8 h-8 w-8 object-cover cursor-zoom-in'}
          onMouseEnter={(e) => overlayPanelImg.current?.toggle(e)}
          onMouseLeave={(e) => overlayPanelImg.current?.toggle(e)}
        />
      )}
      <OverlayPanel ref={overlayPanelImg}>
        <img
          src={`${import.meta.env.VITE_API_URL}/picture/${rowData.template?.picture_id}?tid=${user?.access_token}`}
          alt={rowData.template?.title}
          className={'min-h-32 min-w-32 h-32 w-32 object-cover'}
        />
      </OverlayPanel>
      <span
        className={'cursor-pointer'}
        onMouseEnter={(e) => overlayPanelRef.current?.toggle(e)}
        onMouseLeave={(e) => overlayPanelRef.current?.toggle(e)}
      >
        {rowData.template?.title}
        <OverlayPanel ref={overlayPanelRef}>
          <div className={'flex flex-col gap-2 text-xs'}>
            <div className={'flex justify-between items-center gap-4 border-b pb-1.5 border-gray-100'}>
              <span>Title:</span>
              <span>{rowData.template?.title}</span>
            </div>
            <div className={'flex justify-between items-center gap-4 border-b pb-1.5 border-gray-100'}>
              <span>Date:</span>
              <span>{rowData.template?.created_at && getCzechDate(rowData.template?.created_at)}</span>
            </div>
            <div className={'flex justify-between items-center gap-4 border-b pb-1.5 border-gray-100'}>
              <span>Design Code:</span>
              <span>{rowData.template?.code}</span>
            </div>
            <div className={'flex justify-between items-center gap-4 border-b pb-1.5 border-gray-100'}>
              <span>Type:</span>
              <span>{rowData.template?.type}</span>
            </div>
            <div className={'flex justify-between items-center gap-4'}>
              <span>Version:</span>
              <span>{rowData.template?.version}</span>
            </div>
          </div>
        </OverlayPanel>
      </span>
    </div>
  );
};

export const labelBodyTemplate = (rowData: Model) => {
  if (rowData.label) {
    return (
      <span className={`py-0.5 px-1 text-xs inline-flex justify-center items-center border ${getLabel(rowData.label_color ?? '')}`}>
        {rowData.label}
      </span>
    );
  }
};

export const branchBodyTemplate = (rowData: Model) => {
  return (
    <span title={rowData.branch} className={'text-ellipsis max-w-32 text-nowrap overflow-x-hidden block'}>
      {rowData.branch}
    </span>
  );
};

export const versionBodyTemplate = (rowData: Model) => {
  if (rowData.pipeline?.idea_version) {
    return (
      <span title={rowData.pipeline?.idea_version} className={'text-ellipsis max-w-24 text-nowrap overflow-x-hidden block'}>
        {rowData.pipeline?.idea_version}
      </span>
    );
  }
};

export const dateBodyTemplate = (rowData: Model) => {
  if (rowData.created_at) {
    return getCzechDate(rowData.created_at);
  }
};

export const rateBodyTemplate = (rowData: Model) => {
  if (rowData.pipeline?.success_rate) {
    return (
      <div className={`flex justify-center text-black items-center py-0.5 px-1 border ${getRateColor(rowData.pipeline.success_rate)}`}>
        <span>{rowData.pipeline.success_rate.toFixed(2)}%</span>
        <span className={'ml-1.5 text-[10px]'}>({rowData.pipeline.rows_count})</span>
      </div>
    );
  }

  if (rowData.pipeline?.success_rate === 0) {
    return (
      <div className={`flex justify-center text-black items-center py-0.5 px-1 border bg-gray-50 border-gray-200`}>
        <span>0</span>
        <span className={'ml-1.5 text-[10px]'}>(0)</span>
      </div>
    );
  }
};

export const pipelineBodyTemplate = (rowData: Model) => {
  const overlayPanelPipe = useRef<OverlayPanel>(null);

  if (rowData.state) {
    return (
      <a
        href={rowData.azure_pipeline_url}
        target={'_blank'}
        rel={'noreferrer'}
        className={'inline-flex items-center transition-all hover:opacity-70'}
        onMouseEnter={(e) => overlayPanelPipe.current?.toggle(e)}
        onMouseLeave={(e) => overlayPanelPipe.current?.toggle(e)}
      >
        {rowData.state ? getPipelineIcon(rowData.state) : getPipelineIcon('paused')}
        {rowData.state && (
          <OverlayPanel className={'text-xs'} ref={overlayPanelPipe}>
            {rowData.state}
          </OverlayPanel>
        )}
      </a>
    );
  }

  return getPipelineIcon('paused');
};

export const fileBodyTemplate = (setDuplicateModelVisible: React.Dispatch<React.SetStateAction<boolean>>) => {
  return (
    <FileCode2 className={'cursor-pointer transition-all hover:text-orange-600'} size={18} onClick={() => setDuplicateModelVisible(true)} />
  );
};

export const actionsBodyTemplate = (
  rowData: Model,
  onActionComplete: () => void,
  setDuplicateModelVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return (
    <div className={'flex items-center gap-3'}>
      {rowData.actions?.map((action) => (
        <span key={`${rowData.uid}-${action}`}>{getActionLink(rowData, action, onActionComplete, setDuplicateModelVisible)}</span>
      ))}
    </div>
  );
};
