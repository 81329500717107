import { Model } from '@/interfaces/Model.ts';
import { yaml } from '@codemirror/lang-yaml';
import { githubLight } from '@uiw/codemirror-theme-github';
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useRef } from 'react';
import { toast } from 'react-toastify';

interface DuplicateModelProps {
  visible: boolean;
  onHide: () => void;
  model: Model | null;
}

const AddModel: React.FC<DuplicateModelProps> = ({ visible, onHide, model }) => {
  const editorRef = useRef<ReactCodeMirrorRef>(null);

  const downloadFile = () => {
    const element = document.createElement('a');
    // @ts-ignore
    const file = new Blob([model?.ml_config], { type: 'text/yaml' });
    element.href = URL.createObjectURL(file);
    const sanitizedTitle = model?.title ? `ml_config-${model.title.replace(/\s+/g, '_').toLowerCase()}` : 'ml_config';
    element.download = `${sanitizedTitle}.yaml`;
    document.body.appendChild(element);
    element.click();
  };

  const copyToClipboard = () => {
    if (editorRef.current) {
      const code = editorRef.current.view?.state.doc.toString();
      if (code) {
        navigator.clipboard.writeText(code).then(() => {
          toast.info('Code copied to clipboard!', { autoClose: 1500 });
        });
      }
    }
  };

  const footerTemplate = () => {
    return (
      <div className={'flex justify-center gap-3 items-center mt-5'}>
        <Button label="Download file" className={'btn-primary text-sm'} onClick={downloadFile} />
        <Button label="Copy code" className={'btn-secondary text-sm'} onClick={copyToClipboard} />
      </div>
    );
  };

  const headerTitle = () => {
    return model ? `Config for model: ${model.title}` : 'Config';
  };

  return (
    <Dialog
      draggable={false}
      header={headerTitle}
      footer={footerTemplate}
      visible={visible}
      style={{ width: '45rem', maxWidth: '90%' }}
      onHide={onHide}
    >
      <CodeMirror ref={editorRef} value={model?.ml_config} extensions={[yaml()]} readOnly={true} theme={githubLight} lang={'yaml'} />
    </Dialog>
  );
};

export default AddModel;
