// Pipeline success
export const PipeSuccess = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m12.0005 22.0435c5.5469 0 10.0435-4.4966 10.0435-10.0435 0-5.54684-4.4966-10.04346-10.0435-10.04346-5.54685 0-10.04347 4.49662-10.04347 10.04346 0 5.5469 4.49662 10.0435 10.04347 10.0435z"
        fill="#cbe896"
        stroke="#7dc100"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path d="m8.9873 12.0002 2.0087 2.0086 4.0174-4.01734" fill="#cbe896" />
      <path
        d="m8.9873 12.0002 2.0087 2.0086 4.0174-4.01734"
        stroke="#7dc100"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

// Pipeline running
export const PipeRunning = () => {
  return (
    <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m11 21c5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10-5.52285 0-10 4.47715-10 10 0 5.5228 4.47715 10 10 10z"
        fill="#d8d9ff"
      />
      <path d="m9 7 6 4-6 4z" fill="#d8d9ff" />
      <g stroke="#333bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="m11 21c5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10-5.52285 0-10 4.47715-10 10 0 5.5228 4.47715 10 10 10z" />
        <path d="m9 7 6 4-6 4z" />
      </g>
    </svg>
  );
};

// Pipeline failed
export const PipeFailed = () => {
  return (
    <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#ffdfe2" height="22" rx="11" width="22" />
      <g stroke="#fc7d87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="m11 21c5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10-5.52285 0-10 4.47715-10 10 0 5.5228 4.47715 10 10 10z" />
        <path d="m11 7v4" />
        <path d="m11 15h.01" />
      </g>
    </svg>
  );
};

// Pipeline canceled
export const PipeCanceled = () => {
  return (
    <svg fill="none" height="23" viewBox="0 0 23 23" width="23" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#fc7d87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="m11.0435 21.087c5.5468 0 10.0435-4.4967 10.0435-10.0435 0-5.54688-4.4967-10.0435-10.0435-10.0435-5.54688 0-10.0435 4.49662-10.0435 10.0435 0 5.5468 4.49662 10.0435 10.0435 10.0435z" />
        <path d="m7.5 14.5 7-7m0 7-7-7" />
      </g>
    </svg>
  );
};

// Pipeline success
export const PipePaused = () => {
  return (
    <svg fill="none" height="23" viewBox="0 0 23 23" width="23" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m11.5005 22c5.799 0 10.5-4.701 10.5-10.5 0-5.79899-4.701-10.5-10.5-10.5s-10.50001 4.70101-10.50001 10.5c0 5.799 4.70101 10.5 10.50001 10.5z"
        stroke="#bcbcbc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
