import { routes } from '@/constants';
import { Home } from '@/routes/home';
import { NotFound } from '@/routes/notFound';
import { Route, Routes } from 'react-router-dom';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path={routes.home}>
        <Route index={true} element={<Home />} />
        <Route path={routes.notFound} element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default App;
