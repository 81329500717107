import { useApiGet } from '@/api/apiRequests.ts';
import { getModels } from '@/api/services';
import AddModel from '@/components/dialog/addModel';
import ConfigDialog from '@/components/dialog/configDialog.tsx';
import DuplicateModel from '@/components/dialog/duplicateModel.tsx';
import { Model } from '@/interfaces/Model';
import {
  actionsBodyTemplate,
  branchBodyTemplate,
  dateBodyTemplate,
  fileBodyTemplate,
  labelBodyTemplate,
  pipelineBodyTemplate,
  rateBodyTemplate,
  titleWithImageBodyTemplate,
  versionBodyTemplate,
} from '@/utils/tableCols';
import styled from '@emotion/styled';
import { PlusCircle, Search } from 'lucide-react';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable, DataTableFilterMeta, DataTableRowClickEvent } from 'primereact/datatable';
import { IconField } from 'primereact/iconfield';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';

const PageTitle = styled.h1`
  font-size: 2.5rem;
  font-family: "Teko", sans-serif;
`;

const ModelsTable: React.FC = () => {
  const [models, setModels] = useState<Model[]>([]);
  const [isModelsLoading, setIsModelsLoading] = useState<boolean>(true);
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'template.title': { value: null, matchMode: FilterMatchMode.CONTAINS },
    code: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'pipeline.deploy': { value: null, matchMode: FilterMatchMode.CONTAINS },
    title: { value: null, matchMode: FilterMatchMode.CONTAINS },
    label: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // biome-ignore lint/style/useNamingConvention: <explanation>
    user_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [isDuplicateDialogVisible, setIsDuplicateDialogVisible] = useState<boolean>(false);
  const [isConfigDialogVisible, setIsConfigDialogVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<Model | null>(null);
  const { data, isLoading, refetch } = useApiGet(['models'], getModels, {
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (!isLoading && data) {
      setModels(data);
      setIsModelsLoading(false);
    }
  }, [data, isLoading]);

  const handleModelCreated = () => {
    refetch();
  };

  const handleRowClick = (event: DataTableRowClickEvent) => {
    setSelectedRowData(event.data);
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const Filters = { ...filters };
    // @ts-ignore
    // biome-ignore lint/complexity/useLiteralKeys: <explanation>
    Filters['global'].value = value;

    setFilters(Filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex items-center gap-x-8">
        <PageTitle>Models</PageTitle>
        <div className={'flex items-center gap-4 ml-auto'}>
          <IconField iconPosition="left">
            <Search size={20} color={'#F36E22'} className={'p-input-icon'} />
            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search for a model..." />
          </IconField>
          <Button
            className={'btn-secondary'}
            onClick={() => {
              refetch();
              setIsModelsLoading(true);
              setTimeout(() => {
                setIsModelsLoading(false);
              }, 500);
            }}
          >
            Refresh
          </Button>
          <Button className={'btn-primary'} onClick={() => setIsDialogVisible(true)}>
            <PlusCircle size={24} color={'#fff'} className={'mr-2'} />
            Add model
          </Button>
        </div>
      </div>
    );
  };

  if (!models) {
    return;
  }

  return (
    <>
      <DataTable
        value={models}
        paginator={true}
        loading={isModelsLoading}
        rows={20}
        header={renderHeader}
        onRowClick={handleRowClick}
        filters={filters}
        sortField={'created_at'}
        sortOrder={-1}
        removableSort={true}
        globalFilterFields={[
          'template.title',
          'code',
          'pipeline.deploy',
          'pipeline.run_result',
          'title',
          'branch',
          'label',
          'template.author_name',
        ]}
        tableStyle={{ minWidth: '50rem' }}
      >
        <Column
          field="template.title"
          header="ML Template"
          filter={true}
          filterPlaceholder="Search by template"
          sortable={true}
          body={titleWithImageBodyTemplate}
        />
        <Column field="code" header="Design Code" filter={true} filterPlaceholder="Search by code" sortable={true} />
        <Column field="pipeline.run_result" header="" sortable={true} body={pipelineBodyTemplate} />
        <Column field="pipeline.deploy" header="Env" filter={true} filterPlaceholder="Search by environment" sortable={true} />
        <Column field="pipeline.success_rate" header="Success rate" sortable={true} body={rateBodyTemplate} />
        <Column field="created_at" header="Created at" sortable={true} body={dateBodyTemplate} className={'text-nowrap'} />
        <Column field="title" header="Title" filter={true} filterPlaceholder="Search by title" sortable={true} />
        <Column field="ml_config" header="File" body={fileBodyTemplate(setIsConfigDialogVisible)} />
        <Column field="pipeline.idea_version" header="Idea version" body={versionBodyTemplate} />
        <Column field="branch" header="Branch" sortable={true} body={branchBodyTemplate} />
        <Column field="label" header="Tag" filter={true} filterPlaceholder="Search by tag" body={labelBodyTemplate} />
        <Column field="user_name" header="Author" filter={true} filterPlaceholder="Search by author" sortable={true} />
        <Column body={(rowData) => actionsBodyTemplate(rowData, refetch, setIsDuplicateDialogVisible)} />
      </DataTable>

      <ConfirmDialog draggable={false} />
      <AddModel visible={isDialogVisible} onHide={() => setIsDialogVisible(false)} onModelCreated={handleModelCreated} />
      <DuplicateModel
        visible={isDuplicateDialogVisible}
        onHide={() => setIsDuplicateDialogVisible(false)}
        onModelCreated={handleModelCreated}
        rowData={selectedRowData}
      />
      <ConfigDialog visible={isConfigDialogVisible} onHide={() => setIsConfigDialogVisible(false)} model={selectedRowData} />
    </>
  );
};

export default ModelsTable;
