import api from '@/api/api';

export const getIdeaUser = async () => {
  try {
    const response = await api.get('/me');
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.status);
  }
};
