import { routes } from '@/constants';
import { useUser } from '@/contexts/UserContext.tsx';
import styled from '@emotion/styled';
import { LogOut } from 'lucide-react';
import { ReactNode } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

const Header = styled.header`
  width: 100%;
  background: #fff;
  height: 3.5rem;
  padding: 1rem 2rem;
  position: relative;
  z-index: 10;
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserProfile = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  padding-left: 1.5rem;
  border-left: 1px solid #ffcfb3;
`;

const NavButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const NavButton = styled.button`
  position: relative;
  font-family: "Teko", sans-serif;
  font-weight: 500;
  line-height: 1;
  font-size: 1.25rem;
  text-transform: uppercase;
`;

const Main = styled.main`
  margin-bottom: 2rem;
  padding: 2.5rem 2rem;
`;

const Footer = styled.footer`
  width: 100%;
  margin-top: auto;
  font-size: 0.725rem;
  text-align: center;
  padding: 1rem 2rem;
  color: #ffcfb3;
`;

type NavItemType = {
  text: string;
  /** Setting this flag to `true` means that only auth'd users should see the nav item */
  protected: boolean;
  action: () => void;
};

interface LayoutProps {
  children: ReactNode;
}

export const Layout: React.FC<LayoutProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const navigate = useNavigate();
  const { currentUser } = useUser();

  const navItems: NavItemType[] = [
    {
      text: 'Dashboard',
      protected: true,
      action: () => {
        navigate(routes.home);
      },
    },
  ].filter((item) => {
    return auth.isAuthenticated || !item.protected;
  });

  return (
    <>
      <Header>
        <NavBar>
          <img src={'/ideastatica.svg'} alt={'IDEAStatiCa'} />
          {auth.isAuthenticated && (
            <NavButtons>
              {navItems.map((item) => (
                <NavButton key={item.text} onClick={item.action}>
                  {item.text}
                </NavButton>
              ))}
              {currentUser && (
                <UserProfile>
                  <span>
                    {currentUser.firstName} {currentUser.lastName}
                  </span>
                  <LogOut
                    size={18}
                    className={'ml-2 cursor-pointer transition-all text-[#8F8F8F] hover:text-primary'}
                    onClick={() => auth.signoutRedirect()}
                  />
                </UserProfile>
              )}
            </NavButtons>
          )}
        </NavBar>
      </Header>
      <Main>{children}</Main>
      <Footer>App version: {import.meta.env.VITE_APP_VERSION}</Footer>
    </>
  );
};
