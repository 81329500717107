import App from '@/App';
import { ProtectedApp } from '@/components/protectedApp';
import { onSigninCallback, queryClient, userManager } from '@/config/keycloak';
import { UserProvider } from '@/contexts/UserContext';
import { Layout } from '@/layout/layout';
import { QueryClientProvider } from '@tanstack/react-query';
import { PrimeReactProvider } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import '@/styles/index.css';
import '@/styles/tailwind.css';
import 'react-toastify/dist/ReactToastify.min.css';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <BrowserRouter basename="/">
      <AuthProvider userManager={userManager} onSigninCallback={onSigninCallback}>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <PrimeReactProvider value={{ pt: Tailwind }}>
              <Layout>
                <ProtectedApp>
                  <App />
                  <ToastContainer position="bottom-right" autoClose={6000} newestOnTop={true} draggable={false} />
                </ProtectedApp>
              </Layout>
            </PrimeReactProvider>
          </UserProvider>
        </QueryClientProvider>
      </AuthProvider>
    </BrowserRouter>
  </StrictMode>
);
