import { getUser } from '@/api/api.ts';
import { useApiGet } from '@/api/apiRequests';
import { getIdeaUser } from '@/api/services/getIdeaUser';
import { IdeaUser } from '@/interfaces/IdeaUser';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface UserContextType {
  currentUser: IdeaUser | null;
  isUserLoading: boolean;
}

interface UserProviderProps {
  children: ReactNode;
}

const UserContext = createContext<UserContextType>({
  currentUser: null,
  isUserLoading: true,
});

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<IdeaUser | null>(null);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);
  const user = getUser();

  const { data, isLoading, error, isError, isLoadingError } = useApiGet(['user'], getIdeaUser, {
    enabled: !!user?.access_token,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (!isLoading && data) {
      setCurrentUser(data);
      setIsUserLoading(false);
    } else if (isError) {
      console.error('Error fetching user data:', error || isLoadingError);
      setIsUserLoading(false); // End loading if there's an error
    }
  }, [data, isLoading, isError, error, isLoadingError]);

  return <UserContext.Provider value={{ currentUser, isUserLoading }}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
