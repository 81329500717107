import ModelsTable from '@/components/tables/modelsTable';
import React from 'react';

export const Home: React.FC = () => {
  return (
    <>
      <ModelsTable />
    </>
  );
};
