import { useApiGet, useApiSend } from '@/api/apiRequests';
import { getFilters } from '@/api/services';
import { createModel } from '@/api/services/Models';
import { useUser } from '@/contexts/UserContext';
import { Filters } from '@/interfaces/Filters';
import { Model } from '@/interfaces/Model';
import { yaml } from '@codemirror/lang-yaml';
import { githubLight } from '@uiw/codemirror-theme-github';
import CodeMirror from '@uiw/react-codemirror';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';

interface DuplicateModelProps {
  visible: boolean;
  onHide: () => void;
  onModelCreated: () => void;
  rowData: Model | null;
}

const AddModel: React.FC<DuplicateModelProps> = ({ visible, onHide, onModelCreated, rowData }) => {
  const [filters, setFilters] = useState<Filters>();
  const [formData, setFormData] = useState<Model>();
  const [mlTemplate, setMlTemplate] = useState<object | undefined>();
  const { currentUser } = useUser();
  const { data, isLoading, refetch } = useApiGet(['filters'], getFilters, {
    enabled: false,
    refetchOnWindowFocus: true,
  });
  const { mutate } = useApiSend(createModel);
  const colors = [
    { name: 'Orange', key: 'orange' },
    { name: 'Blue', key: 'blue' },
    { name: 'Green', key: 'green' },
    { name: 'Yellow', key: 'yellow' },
    { name: 'Black', key: 'black' },
  ];

  const updateMlTemplate = (e: any) => {
    setFormData({
      ...formData,
      // biome-ignore lint/style/useNamingConvention: <explanation>
      template_id: e.value.template_id,
      // biome-ignore lint/style/useNamingConvention: <explanation>
      azure_pipeline_id: e.value.azure_pipeline_id,
    });
    setMlTemplate(e.value);
  };

  const mlSelectedTemplate = (option: DropdownProps, props: DropdownProps) => {
    if (option) {
      return <span>{option.title}</span>;
    }
    return <span>{props.placeholder}</span>;
  };

  const changeColorValue = (e: any) => {
    setFormData({
      ...formData,
      // biome-ignore lint/style/useNamingConvention: <explanation>
      label_color: e.key,
    });
  };

  const handleSave = () => {
    if (formData) {
      mutate(formData, {
        onSuccess: () => {
          onModelCreated();
          onHide();
        },
      });
    }
  };

  const cancelForm = () => {
    setFormData({});
    onHide();
  };

  const onChangeEditor = React.useCallback(
    (val: string) => {
      // biome-ignore lint/style/useNamingConvention: <explanation>
      setFormData({ ...formData, ml_config: val });
    },
    [formData]
  );

  // Fetch filters when the modal becomes visible
  useEffect(() => {
    if (visible) {
      refetch();
    }
  }, [visible, refetch]);

  useEffect(() => {
    if (!isLoading && data && currentUser) {
      setFilters(data);
      setFormData({
        ...formData,
        // biome-ignore lint/style/useNamingConvention: <explanation>
        user_name: `${currentUser.firstName} ${currentUser.lastName}`,
        // biome-ignore lint/style/useNamingConvention: <explanation>
        user_id: currentUser.username,
      });
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (rowData) {
      setFormData({
        ...formData,
        title: rowData.title,
        code: rowData.code,
        label: rowData.label,
        branch: rowData.branch,
        // biome-ignore lint/style/useNamingConvention: <explanation>
        ml_config: rowData.ml_config,
        // biome-ignore lint/style/useNamingConvention: <explanation>
        label_color: rowData.label_color,
        // biome-ignore lint/style/useNamingConvention: <explanation>
        template_id: rowData.template_id,
        // biome-ignore lint/style/useNamingConvention: <explanation>
        azure_pipeline_id: rowData.azure_pipeline_id,
      });
      setMlTemplate({
        // biome-ignore lint/style/useNamingConvention: <explanation>
        azure_pipeline_id: rowData.azure_pipeline_id,
        // biome-ignore lint/style/useNamingConvention: <explanation>
        template_id: rowData.template_id,
        title: rowData.template?.title,
      });
    }
  }, [rowData]);

  return (
    <Dialog draggable={false} header="Duplicate Model" visible={visible} style={{ width: '40rem' }} onHide={onHide}>
      <div className={'grid gap-3'}>
        <div className={'flex flex-col w-full'}>
          <label className={'mb-1'} htmlFor="ml_template">
            ML Template
          </label>
          <Dropdown
            value={mlTemplate}
            onChange={(e) => updateMlTemplate(e)}
            options={filters?.templates}
            optionLabel="title"
            placeholder="Select a ML Template"
            filter={true}
            valueTemplate={mlSelectedTemplate}
          />
        </div>
        <div className={'flex flex-col w-full'}>
          <label className={'mb-1'} htmlFor="code">
            Choose Design Code
          </label>
          <Dropdown
            value={formData?.code}
            onChange={(e) => setFormData({ ...formData, code: e.value })}
            options={filters?.codes}
            placeholder="Select a Design Code"
            filter={true}
          />
        </div>
        <div className={'flex flex-col w-full'}>
          <label className={'mb-1'} htmlFor="pipeline_id">
            Pipeline ID
          </label>
          <InputNumber
            id="pipeline_id"
            value={formData?.azure_pipeline_id}
            // biome-ignore lint/style/useNamingConvention: <explanation>
            onChange={(e) => setFormData({ ...formData, azure_pipeline_id: e.value || 0 })}
          />
        </div>
        <div className={'flex flex-col w-full'}>
          <label className={'mb-1'} htmlFor="branch">
            Branch
          </label>
          <InputText
            id="branch"
            type="text"
            value={formData?.branch || ''}
            onChange={(e) => setFormData({ ...formData, branch: e.target.value })}
          />
        </div>
        <div className={'flex flex-col w-full'}>
          <label className={'mb-1'} htmlFor="ml_config">
            Model_CFG
          </label>
          <CodeMirror
            id="ml_config"
            value={formData?.ml_config || ''}
            placeholder="Please enter YAML configuration."
            onChange={onChangeEditor}
            extensions={[yaml()]}
            height={'250px'}
            theme={githubLight}
            lang={'yaml'}
            style={{ fontSize: '12px' }}
          />
        </div>
        <div className={'grid grid-cols-1 w-full gap-3 md:grid-cols-2'}>
          <div className={'flex flex-col w-full'}>
            <label className={'mb-1'} htmlFor="label">
              Add a tag
            </label>
            <InputText
              id="label"
              type="text"
              value={formData?.label || ''}
              onChange={(e) => setFormData({ ...formData, label: e.target.value })}
            />
          </div>
          <div className={'flex flex-col w-full'}>
            <label className={'mb-1'} htmlFor="label_color">
              Choose color
            </label>
            <div className={'flex items-center gap-2'}>
              {colors.map((item) => {
                return (
                  <div key={item.key}>
                    <RadioButton
                      inputId={item.key}
                      name="label_color"
                      value={item}
                      onChange={(e) => changeColorValue(e.value)}
                      className={'hidden'}
                    />
                    <label htmlFor={item.key} className={`label-color ${formData?.label_color === item.key ? 'is-active' : ''}`}>
                      <span className={item.key} />
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={'flex flex-col w-full'}>
          <label className={'mb-1'} htmlFor="title">
            Title
          </label>
          <InputText
            id="title"
            type="text"
            value={formData?.title || ''}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
        </div>
        <div className={'flex justify-between gap-3'}>
          <Button label="Cancel" className={'btn-secondary'} onClick={cancelForm} />
          <Button label="Save" className={'btn-primary'} onClick={handleSave} />
        </div>
      </div>
    </Dialog>
  );
};

export default AddModel;
